import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alert-dialog"},[_c(VDialog,{attrs:{"max-width":_vm.width || 300},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VAlert,{staticClass:"alert-title",attrs:{"type":_vm.type || 'info'}},[_vm._t(_vm.title)],2),_c(VCardText,{staticClass:"alert-content"},[_vm._t(_vm.body)],2),_c(VCardActions,{staticClass:"alert-buttons"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_c('span',[_vm._v(_vm._s(_vm.closeBtnText || "Close"))])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }