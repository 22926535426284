
import Vue from "vue";
import AlertDialog from "@/components/AlertDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import { Dialog } from "@/types";

export default Vue.extend({
  name: "Devices",
  components: {
    AlertDialog,
    ConfirmDialog,
  },
  data: () => ({
    domain: "",
    token: "",
    search: "",
    headers: [
      { text: "Hardware model", value: "hardwareModel" },
      { text: "Serial number", value: "serialNumber" },
      { text: "Device state", value: "deviceState" },
      { text: "Device ID", value: "preProvisionedDeviceId" },
      { text: "Create time", value: "createTime" },
      { text: "", value: "actions", sortable: false, align: "right" },
    ],
  }),
  created() {
    this.loadDomains();
  },
  computed: {
    ...mapState("devices", ["domains", "customerId", "tokens", "devices"]),
    ...mapGetters("auth", ["userRole"]),
  },
  methods: {
    ...mapActions("devices", [
      "loadDomains",
      "loadTokens",
      "listPreProvisionedDevices",
      "deletePreProvisionedDevice",
    ]),
    deleteDevice(deviceId: string): void {
      const errorDialog = this.$refs.errorDialog as Dialog;
      this.deletePreProvisionedDevice(deviceId).catch((err) => {
        errorDialog.open();
        console.log(err);
      });
    },
  },
  filters: {
    formatDate(value: string): string {
      return moment(value).format("DD-MM-YYYY hh:mm:ss");
    },
  },
});
